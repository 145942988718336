<template>
    <PopupDialog
        :show="show"
        :hideScroll="false"
        :maxWidth="'md'"
        :hasButtons="false"
        :closeable="true"
        @closeModal="closeModal">
        <template #bodyContainer>
            <div class="relative m-auto flex w-full flex-col bg-white">
                <font-awesome-icon
                    @click="closeModal"
                    @touchend="closeModal"
                    class="absolute right-5 top-5 h-[27px] w-[27px] text-black md:hover:cursor-pointer"
                    :icon="['fas', 'xmark']" />

                <div class="my-4 flex w-auto flex-col items-center justify-center px-2">
                    <div class="my-4 flex w-auto flex-col items-center justify-center px-2">
                        <div class="relative">
                            <div class="justify-center">
                                <div
                                    v-if="!post.creator?.avatar"
                                    class="mx-auto mb-[5px] h-[80px] w-[80px] rounded-full">
                                    <font-awesome-icon
                                        icon="fa-solid fa-user-circle"
                                        class="h-[80px] w-[80px] rounded-full bg-gray-300 text-neutral-700" />
                                </div>
                                <img
                                    v-else
                                    alt="avatar"
                                    :src="post.creator.avatar"
                                    class="mx-auto mb-[5px] h-[80px] w-[80px] rounded-full" />
                                <div class="flex items-center justify-center">
                                    <div class="flex flex-col pl-[11px] text-center">
                                        <p
                                            class="mb-[5px] block truncate text-[18px] font-medium capitalize leading-[18px] tracking-[.54px] text-graycontrol">
                                            {{ post.creator?.name }}
                                        </p>
                                        <p class="relative block text-[14px] text-graycontrol">
                                            @{{ post.creator?.profile_url }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="mb-[17px] max-w-[330px] pt-[25px] text-left text-[14px] leading-normal tracking-wide text-graycontrol">
                            {{ post.description }}
                        </div>

                        <div
                            v-if="insufficientFunds"
                            class="mb-5 w-full rounded-md border border-[#f1e0b0] bg-[#fbf7dc] p-2">
                            <p class="text-[#a27933]">
                                Not enough funds in wallet.
                                <span @click="goToWallet" class="font-bold text-[#a27933]"
                                    >Add funds</span
                                >
                                .
                            </p>
                        </div>

                        <div v-else-if="errorMessage" class="mb-5 rounded-lg bg-pink-100 p-2">
                            <p class="text-red-600">{{ errorMessage }}</p>
                        </div>

                        <font-awesome-icon :icon="['fas', 'spinner']" spin v-if="loading" />

                        <AppButton
                            v-else
                            @click="purchasePost"
                            @touchend="purchasePost"
                            :text="`CONFIRM PURCHASE - $${post.price}`"
                            ui="primary" />
                    </div>
                </div>
            </div>
        </template>
    </PopupDialog>
</template>

<script setup>
    import { Link } from '@inertiajs/vue3'
    import { router } from '@inertiajs/vue3'
    import AppButton from '@/Components/ui-components/AppButton.vue'
    import PopupDialog from '@/Components/ui-components/PopupDialog.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { storeToRefs } from 'pinia'
    import { useBillingTransactionStore } from '@/store/billing_transaction.js'

    const transactionStore = useBillingTransactionStore()
    const { loading, errorMessage, insufficientFunds, purchaseDone } = storeToRefs(transactionStore)

    const props = defineProps({
        post: { type: Object, default: {} },
        show: {
            type: Boolean,
            default: false,
        },
    })

    const emit = defineEmits(['onPurchasePost', 'closeModal'])

    function closeModal() {
        emit('closeModal')
        transactionStore.clearPurchaseResponse()
    }

    function purchasePost() {
        transactionStore.makePurchasePost(props.post).then(() => {
            if (purchaseDone.value) {
                emit('onPurchasePost')
            } else if (insufficientFunds.value && !purchaseDone.value) {
                return
            }
        })
    }

    function goToWallet() {
        let returnUrl = encodeURIComponent(router.page.url)
        router.visit(`/profile/wallet?return_url=${returnUrl}`)
    }
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>

// location.href = route('profile.message-lists.edit', [data.id], { // backref:
'/profile/message-lists/', // }) // router.visit( // route('profile.message-lists.edit', [data.id],
{ // backref: '/profile/message-lists/', // }), // )
